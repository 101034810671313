import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.module.scss";
import Home from "./Pages/Home/Home";
import Header from "./Components/Header/Header";
import Menu from "./Pages/Menu/Menu";
import MenuItem from "./Pages/MenuItem/MenuItem";
import Contact from "./Pages/Contact/Contact";
import Footer from "./Components/Footer/Footer";
import Career from "./Pages/Career/Career";
import CareerPosition from "./Pages/CareerPosition/CareerPosition";
import Blog from "./Pages/Blog/Blog";
import About from "./Pages/About/About";
import BlogpostPage from "./Pages/BlogpostPage/BlogpostPage";
import Recipes from "./Pages/Recipes/Recipes";
import RecipesPage from "./Pages/RecipePage/RecipePage";
import Terms from "./Pages/Terms/Terms";
import ThankYou from "./Pages/ThankYou/ThankYou";
import ComingSoon from "./Pages/ComingSoon/ComingSoon";

function App() {
  const isComingSoon = true;

  return (
    <div>
      {isComingSoon ? (
        <>
          <Routes>
            <Route path="/" element={<ComingSoon />} />
          </Routes>
        </>
      ) : (
        <> 
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/menu" element={<Menu />} />
            <Route path="/menu/:id" element={<MenuItem />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/career" element={<Career />} />
            <Route path="/career/:position" element={<CareerPosition />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<BlogpostPage />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/recipes" element={<Recipes />} />
            <Route path="/recipes/:id" element={<RecipesPage />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/thank-you" element={<ThankYou />} />
          </Routes>
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
